@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.fant-micro-main[data-v-5954443c] {
  background: url(../img/bg.d01eeadf.png) no-repeat center center;
  background-size: 100% 100%;
}
.portal_wrap[data-v-5954443c] {
  padding: 0.16rem 0.24rem 0.24rem 0.24rem;
  overflow-y: auto;
}
.portal_container[data-v-5954443c] {
  width: 14.36rem;
  height: 9.6rem;
  margin: 0 auto;
  border: 0.02rem solid #fff;
  border-radius: 0.16rem;
  background: rgba(255, 255, 255, 0.3);
}
.portal_left[data-v-5954443c] {
  width: 10.26rem;
  float: left;
}
.app_type[data-v-5954443c] {
  width: 100%;
  box-sizing: border-box;
  padding: 0.34rem 0 0 0.5rem;
  float: left;
}
.app_type[data-v-5954443c]:nth-of-type(1) {
  padding-top: 0.42rem;
}
.type_name[data-v-5954443c] {
  width: 0.36rem;
  height: 1.56rem;
  margin-right: 0.24rem;
  float: left;
  border-radius: 0.04rem;
  background-color: #d4dbfc;
  letter-spacing: 0.08rem;
  font-size: 0.14rem;
  color: #7288ee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  writing-mode: vertical-lr;
  text-align: center;
}
.app_type_market .type_name[data-v-5954443c] {
  height: 3.32rem;
}
.app_type_tool .type_name[data-v-5954443c] {
  height: 1.08rem;
}
