@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.portal_right[data-v-2a0042be] {
  width: 3.6rem;
  float: left;
}
.data_box[data-v-2a0042be] {
  width: 100%;
  margin-top: 0.42rem;
  border-radius: 0.08rem;
  background-color: #fff;
  overflow: hidden;
}
.data_title[data-v-2a0042be] {
  width: 100%;
  height: 0.4rem;
  background: url(../img/title_bg.6d351665.png) no-repeat center center;
  background-size: 3.6rem 0.4rem;
  display: flex;
  align-items: center;
}
.data_title img[data-v-2a0042be] {
    width: 0.2rem;
    height: 0.2rem;
    margin-left: 0.16rem;
    margin-right: 0.08rem;
}
.data_title p[data-v-2a0042be] {
    font-size: 0.14rem;
    font-weight: bold;
    color: #243154;
}
ul.notice_list[data-v-2a0042be] {
  width: 100%;
  height: 0.92rem;
  padding: 0 0.2rem;
  margin-bottom: 0.16rem;
}
ul.notice_list li[data-v-2a0042be] {
    width: 100%;
    height: 0.2rem;
    line-height: 0.2rem;
    margin-top: 0.16rem;
    font-size: 0.12rem;
    display: flex;
    justify-content: space-between;
}
ul.notice_list li p[data-v-2a0042be] {
      color: #333;
      cursor: pointer;
      display: flex;
}
ul.notice_list li p img[data-v-2a0042be] {
        width: 0.3rem;
        height: 0.18rem;
        margin-left: 0.04rem;
        transform: translateY(-0.04rem);
}
ul.notice_list li p[data-v-2a0042be]:hover {
      color: #1c70ff;
}
ul.notice_list li span.notice_date[data-v-2a0042be] {
      color: #999;
}
.notice_modal[data-v-2a0042be] {
  padding-bottom: 0.2rem;
}
.notice_modal h3[data-v-2a0042be] {
    padding-top: 0.48rem;
    padding-bottom: 0.16rem;
    font-size: 0.2rem;
    font-weight: bold;
    text-align: center;
    color: #488aff;
}
.notice_modal p[data-v-2a0042be] {
    text-indent: 2em;
    font-size: 0.15rem;
    color: #444;
    margin-bottom: 0.06rem;
}
.faq_box[data-v-2a0042be] {
  margin-top: 0.16rem;
  position: relative;
}
ul.faq_list[data-v-2a0042be] {
  width: 100%;
  height: 6.54rem;
  padding: 0.02rem 0.2rem 0.22rem;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}
ul.faq_list li[data-v-2a0042be] {
    width: 3.2rem;
    float: left;
    margin-top: 0.1rem;
    border-radius: 0.08rem 0 0.08rem 0;
    font-size: 0.12rem;
    background: linear-gradient(180deg, #f7f8fa 0%, #f7f8fa 100%);
    position: relative;
}
ul.faq_list li img[data-v-2a0042be] {
      width: 0.25rem;
      height: 0.31rem;
      position: absolute;
      left: 0.09rem;
      top: 0.07rem;
}
ul.faq_list li h3[data-v-2a0042be] {
      width: 2.88rem;
      height: 0.2rem;
      margin-left: 0.09rem;
      padding: 0.1rem 0.07rem 0.08rem;
      box-sizing: content-box;
      border-bottom: 0.01rem dotted #d9d5d5;
      color: #647ced;
}
ul.faq_list li p[data-v-2a0042be] {
      width: 2.88rem;
      line-height: 0.2rem;
      margin: 0.1rem 0.16rem;
      color: #666;
}
ul.faq_list li b[data-v-2a0042be] {
      color: #333;
}
ul[data-v-2a0042be]::-webkit-scrollbar-thumb {
  background-color: #d4dbfc;
  border-radius: 0.1rem;
}
.faq_footer_bg[data-v-2a0042be] {
  width: 100%;
  height: 0.16rem;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0 0 0.2rem 0.2rem rgba(255, 255, 255, 0.9);
}
