@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.app_list[data-v-24c051ae] {
  width: calc(100% - 0.6rem);
  display: inline-block;
}
.app_list .app_item[data-v-24c051ae] {
    width: 2.88rem;
    height: 1.56rem;
    display: inline-block;
    margin-right: 0.16rem;
    border-radius: 0.12rem;
    background-color: #fff;
    box-shadow: 0 0.02rem 0.08rem 0 rgba(148, 158, 184, 0.2);
    position: relative;
    overflow: hidden;
    transition: all .2s;
}
.app_list .app_item .no_auth[data-v-24c051ae] {
      width: 0.64rem;
      height: 0.2rem;
      line-height: 0.2rem;
      text-align: center;
      font-size: 0.12rem;
      color: #87909e;
      background-color: #ebeef3;
      border-radius: 0 0.12rem 0 0.24rem;
      position: absolute;
      top: 0;
      right: 0;
}
@media (max-width: 1800px) {
.app_list .app_item .no_auth[data-v-24c051ae] {
        width: 0.8rem;
        height: 0.25rem;
        line-height: 0.25rem;
        border-radius: 0 0.15rem 0 0.3rem;
        font-size: 0.1rem;
        transform: scale(0.8);
        top: -0.03rem;
        right: -0.08rem;
}
}
.app_list .app_item .app_info[data-v-24c051ae] {
      width: 2.4rem;
      height: 0.64rem;
      margin: 0.22rem 0.2rem 0.2rem;
      position: relative;
      cursor: pointer;
}
.app_list .app_item .app_info img[data-v-24c051ae] {
        width: 0.64rem;
        height: 0.64rem;
        margin-right: 0.16rem;
        float: left;
}
.app_list .app_item .app_info .app_title[data-v-24c051ae] {
        width: 1.6rem;
        float: left;
}
.app_list .app_item .app_info .app_title h3[data-v-24c051ae] {
          height: 0.2rem;
          line-height: 0.2rem;
          margin: 0.07rem 0 0.1rem;
          font-size: 0.16rem;
          font-weight: bold;
          color: #333;
}
.app_list .app_item .app_info .app_title p[data-v-24c051ae] {
          width: 1.42rem;
          height: 0.2rem;
          font-size: 0.12rem;
          color: #999;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
}
.app_list .app_item .app_info p.icon_jump[data-v-24c051ae] {
        width: 0.2rem;
        height: 0.2rem;
        position: absolute;
        top: 0.07rem;
        right: -0.1rem;
}
.app_list .app_item .app_info p.icon_jump img[data-v-24c051ae] {
          width: 100%;
          height: 100%;
          display: inline-block;
}
.app_list .app_item .app_sub_entry[data-v-24c051ae] {
      margin-left: 0.2rem;
}
.app_list .app_item .app_sub_entry span[data-v-24c051ae] {
        width: 0.7rem;
        height: 0.26rem;
        line-height: 0.26rem;
        float: left;
        margin-right: 0.1rem;
        border: 0.01rem solid #ebeef3;
        border-radius: 0.43rem;
        text-align: center;
        font-size: 0.12rem;
        color: #c9ced7;
}
.app_list .app_item .app_sub_entry_auth span[data-v-24c051ae] {
      border-color: #1c70ff;
      color: #1c70ff;
      transition: all .2s;
}
.app_list .app_item .app_sub_entry_auth span[data-v-24c051ae]:hover {
      background-color: rgba(28, 112, 255, 0.1);
      cursor: pointer;
}
@media (max-width: 1800px) {
.app_list .app_item .app_sub_entry span[data-v-24c051ae] {
        width: 0.82rem;
        height: 0.36rem;
        line-height: 0.36rem;
        border-radius: 0.18rem;
        margin-right: 0.02rem;
        font-size: 0.1rem;
        transform: scale(0.8) translateX(-0.1rem);
}
}
.app_list .app_item[data-v-24c051ae]:hover {
    transform: scale(1.05);
    transition: all .2s;
}
.app_type_tool .app_item[data-v-24c051ae] {
  height: 1.08rem;
}
.app_type_market .app_item[data-v-24c051ae]:nth-of-type(1), .app_type_market .app_item[data-v-24c051ae]:nth-of-type(2), .app_type_market .app_item[data-v-24c051ae]:nth-of-type(3) {
  margin-bottom: 0.14rem;
}
